import InterestPill from "components/InterestPill";
import { UserContext } from "context/userCodeContext";
import { useContext } from "react";
import styles from './UserInterestsCard.module.css';

const UserInterestsCard = () => {
    const { user } = useContext(UserContext);
    const interests = user?.interests || [];
    const displayedInterests = interests.slice(0, 10);
    const remainingInterestsCount = interests.length > 10 ? interests.length - 10 : 0;

    return (
        <div className={`${styles['user-summary-card']} block p-6 shadow break-words text-left mt-[16px] mb-6 md:mb-0`}>
            <h5 className="text-xl font-semibold tracking-tight text-gray-900text-white font-sans">Interests</h5>
            <div className="flex flex-wrap gap-1">
                {interests.length > 0 ? <>
                    {displayedInterests.map((interest, index) => (
                        <InterestPill key={index} interest={interest} />
                    ))}
                    {remainingInterestsCount > 0 && (
                        <InterestPill interest={`+${remainingInterestsCount} More`} />
                    )}</> : <p className="font-normaltext-neutral-500 font-sans leading-relaxed">No Interests</p>}
            </div>
        </div>
    );
}

export default UserInterestsCard;
