import { getUserMediaInfo, openAppOrBrowser } from 'Utils';
import OutlinedButton from 'components/OutlinedButton';
import PreviewSection from 'components/PreviewSection';
import PrimaryButton from 'components/PrimaryButton';
import UserCard from 'components/UserCard';
import { InviteContext } from 'context/inviteCodeContext';
import { HeroSectionProps } from 'interfaces'; // Import HeroSectionProps interface
import React, { useContext, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';



const HeroSection: React.FC<HeroSectionProps & { scrollToParticipants: () => void }> = ({ data, roomId, introductions, scrollToParticipants, onMessageBubbleClick }) => {
    const [activeMedia, setActiveMedia] = useState<string | null>(null);
    const [playingStates, setPlayingStates] = useState<{ [key: string]: boolean }>({});
    const audioRefs = useRef<{ [key: string]: HTMLAudioElement | null }>({});
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const { inviteCode } = useContext(InviteContext);

    const pauseAllMedia = () => {
        // Pause any currently playing video
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
            videoRef.current = null;
        }
        // Pause any currently playing audio
        Object.values(audioRefs.current).forEach(audio => {
            if (audio) {
                audio.pause();
                audio = null;
            }
        });
        // Reset playing states
        setPlayingStates({});
    };

    const handleVideoPlay = (introId: string) => {
        pauseAllMedia();
        if (activeMedia && audioRefs.current[activeMedia]) {
            audioRefs.current[activeMedia]?.pause();
            audioRefs.current[activeMedia]!.currentTime = 0;
        }
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause();
            videoRef.current!.currentTime = 0;
            videoRef.current = null;
        }
        const newPlayingStates = { ...playingStates, [introId]: true };
        setPlayingStates(newPlayingStates);
        setActiveMedia(introId);
    }

    const handleAudioPlay = (introId: string) => {
        pauseAllMedia();
        setPlayingStates({ [introId]: true });
        if (audioRefs.current[introId]) {
            audioRefs.current[introId]?.play();
        }
    };

    const handlePause = () => {
        pauseAllMedia();
    };

    return (
        <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-3 md:flex-row md:space-x-4">
                <div className="flex-1 md:px-4 md:ml-2 flex flex-col space-y-4 md:mb-6">
                    <div className="text-2xl mt-4 md:mt-4 text-white font-bold md:text-left md:text-4xl px-4 break-all">
                        You have been invited to&nbsp;
                        <span className="block md:inline text-cyan-300">"{data.roundtable.name ?? 'Harvard Alumni'}"</span>
                    </div>
                    <div className="md:block hidden">
                        <div className="grid grid-cols-2 gap-5 mx-2">
                            {data.roundtable.RoundtableUsers?.slice(0, 4).map((user, index) => {
                                const { intro, isUserPlaying, isAudio } = getUserMediaInfo(user, introductions, playingStates);

                                return (
                                    <UserCard
                                        key={index}
                                        user={user}
                                        intro={intro}
                                        isUserPlaying={isUserPlaying}
                                        isAudio={isAudio}
                                        onPlay={intro ? (() => isAudio ? handleAudioPlay(intro.introId) : handleVideoPlay(intro.introId)) : () => { }}
                                        onPause={handlePause}
                                        audioRef={(el: HTMLAudioElement | null) => (audioRefs.current[intro?.introId || ''] = el)}
                                        isMobile={false}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="block md:hidden">
                        <Carousel
                            showArrows={false}
                            showThumbs={false}
                            showIndicators={false}
                            showStatus={false}
                            swipeable={true}
                            centerMode={(data.roundtable.RoundtableUsers?.length ?? 0) > 1}
                            infiniteLoop={true}
                            centerSlidePercentage={85}
                            className='md:mt-8'>
                            {data.roundtable.RoundtableUsers?.map((user, index) => {

                                const { intro, isUserPlaying, isAudio } = getUserMediaInfo(user, introductions, playingStates);

                                return (
                                    <UserCard
                                        key={index}
                                        user={user}
                                        intro={intro}
                                        isUserPlaying={isUserPlaying}
                                        isAudio={isAudio}
                                        onPlay={intro ? (() => isAudio ? handleAudioPlay(intro.introId) : handleVideoPlay(intro.introId)) : () => { }}
                                        onPause={handlePause}
                                        audioRef={(el: HTMLAudioElement | null) => (audioRefs.current[intro?.introId || ''] = el)}
                                        isMobile={true}
                                    />
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="block md:hidden text-white mt-4">
                        <div className="font-bold text-2xl">Meet the Participants</div>
                        <div className="text-gray-500 text-lg">{data.roundtable.RoundtableUsers?.length} Participants in Roundtable</div>
                    </div>
                    <div className="flex flex-row justify-center md:justify-start space-x-2 items-center">
                        <div className='mt-4 mb-5 md:mt-5 px-2'>
                            <PrimaryButton onClick={() => { openAppOrBrowser({ roomId, inviteCode }) }} title="Join Roundtable" />
                        </div>
                        <div className="hidden md:block">
                            <OutlinedButton onClick={scrollToParticipants} title="All Participants" />
                        </div>
                    </div>
                </div>
                <div className="flex-1 bg-gradient-to-b from-cyan-300/75 to-transparent py-4 hidden md:block rounded-2xl">
                    <PreviewSection data={data} onMessageBubbleClick={onMessageBubbleClick} />
                </div>
            </div>
        </div>
    );
}

export default HeroSection;
