import { openAppOrBrowser } from "Utils";
import AppStoreBtn from "assets/images/app-store-btn.svg";
import GoogleStoreBtn from "assets/images/google-store-btn.svg";
import Navbar from 'components/Navbar';
import { UserInfoCard } from 'components/UserInfoCard';
import UserInterestsCard from 'components/UserInterestsCard';
import UserSummaryCard from 'components/UserSummaryCard';
import { UserContext } from 'context/userCodeContext';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { fetchUserData } from 'services/apiService';
import styles from './UserPage.module.css';

const UserPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const backendEnv = queryParams.get('env') || null;
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const { user, setUser } = useContext(UserContext);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const roomData = await fetchUserData(userId ?? "", backendEnv);
                setUser(roomData.data);
            } catch (error: any) {
                setError(error["message"]);
            } finally {
                setLoading(false); // Set loading to false when fetch is complete
            }
        };

        fetchData();
    }, [userId, backendEnv, setUser]);

    if (loading) {
        return (
            <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-r-2 border-cyan-300"></div>
            </div>
        );
    }

    if (error || !user) {
        return (
            <div className="flex flex-col items-center justify-center h-screen text-center">
                <h1 className="bg-gradient-to-r from-white to-cyan-500 text-transparent bg-clip-text text-7xl font-bold mb-6">404</h1>
                <h1 className="text-4xl font-bold mb-2 text-white">User not found</h1>
                <p className='text-neutral-500'>Request failed with status code 404</p>
            </div>
        );
    }

    return (
        <div className={`${styles['main-content']}`}>
            <Navbar userId={userId} />
            <div className='py-10 text-white text-center font-sans font-bold text-2xl px-6 md:px-0'>
                "<span className='text-cyan-300'>{user.name}</span>" invited you to view their Roundtables profile
            </div>
            <div className="sm:grid max-md:grid-cols-1 max-lg:grid-cols-1 lg:grid-cols-2 gap-8 px-4 md:px-28">
                <UserInfoCard />
                <div className={`${styles['radial-background']} rounded-md text-white md:h-9/10`}>
                    <div className={styles["user-info-section"]}>
                        <UserSummaryCard />
                        <UserInterestsCard />
                    </div>
                </div>
            </div>
            <div className={`bg-[#202223] w-full py-12 lg:py-20 ${styles['footer']} backdrop-blur-md grid grid-cols-1 md:grid-cols-2 mt-8`}>
                <div className="hidden md:block">{/* Empty first column on larger screens */}</div>
                <div className="flex flex-col items-center justify-center text-white space-y-4">
                    <p className='font-sans text-2xl text-center px-10  lg:px-20'>
                        Download the Roundtables app on iPhone or Android to view <span className='text-cyan-300'>{user.name}'s</span> complete profile and connect.
                    </p>
                    <div className="flex flex-row justify-center gap-2">
                        <img src={AppStoreBtn} alt="App Store Logo" className="w-32 lg:w-44" onClick={() => openAppOrBrowser({ userId: user.id })} />
                        <img src={GoogleStoreBtn} alt="Google Play Logo" className="w-36 lg:w-48" onClick={() => openAppOrBrowser({ userId: user.id })} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserPage;
