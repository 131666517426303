import { UserContext } from "context/userCodeContext";
import { useContext } from "react";
import styles from './UserSummaryCard.module.css';


const UserSummaryCard = () => {
    const { user } = useContext(UserContext);

    return (
        <div className={`${styles['user-summary-card']} block p-6 shadow break-words text-left max-sm:my-8`}>
            <h5 className="text-xl font-semibold tracking-tight text-white font-sans">Summary</h5>
            <p className={`font-normal text-gray-700 ${user?.summary ? "text-neutral-200" : "text-neutral-500"} font-sans leading-relaxed`}>{user?.summary ? user?.summary : "No Summary"}</p>
        </div>
    )
}

export default UserSummaryCard